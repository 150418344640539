import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import { useLocation, useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Modal from './Modal'; 
import ListView from './ListView';
import { FaArrowUp, FaArrowDown, FaSync } from 'react-icons/fa';
import Quill from 'quill'; 
import ImageResize from 'quill-image-resize';


function MainApp() {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const contentSet = useRef(false);
  const [fileName, setFileName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [summary, setSummary] = useState('');
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const fileIdFromUrl = params.get('file-id'); // URLから取得したfile-idを直接設定
  const contentEditableRef = useRef(null);
  const [menuPosition, setMenuPosition] = useState({ top: 1000, left: 150 });
  const [showMenu, setShowMenu] = useState(false);
  const [showModal, setShowModal] = useState(false); // ここでshowModalとsetShowModalを定義
  const [modalContent, setModalContent] = useState('');
  const [isPlainText, setIsPlainText] = useState(true); 
  const [showIdeaModal, setShowIdeaModal] = useState(false);
  const [contextText, setContextText] = useState('');
  const [questionText, setQuestionText] = useState('');
  const [responseText, setResponseText] = useState('');
  const [modalSource, setModalSource] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // サイドバーの状態を管理
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null); // setSelectedFileの定義を追加
  const [sidebarData, setSidebarData] = useState([]);
  const [selectedFileId, setSelectedFileId] = useState(fileIdFromUrl); // 選択されたファイルIDを管理する状態を追加
  const sidebarRef = useRef(null);
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(false); // 右サイドバーの状態を管理
  const [responseTexts, setResponseTexts] = useState([]); // 複数の応答を管理するための状態
  const [thoughts, setThoughts] = useState([]);
  const apiUrl = `http://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;
  const [showToast, setShowToast] = useState(false); // トーストの表示を管理する状態
  const [toastMessage, setToastMessage] = useState(''); // トーストに表示するメッセージ
  const quillRef = useRef(null); // Quill用の参照を追加

  // トーストを表示する関数
  const showToastMessage = (message) => {
    setToastMessage(message);
    setShowToast(true);
    
    // 3秒後に自動でトーストを非表示にする
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  };

  useEffect(() => {
    if (quillRef.current && !quillRef.current.quill) {

          // 画像リサイズモジュールをQuillに登録
Quill.register('modules/imageResize', ImageResize);

      // const FontSizeStyle = Quill.import('attributors/style/size');
      // FontSizeStyle.whitelist = ['small', 'normal', 'large', 'huge']; // 使用可能なフォントサイズ
      // Quill.register(FontSizeStyle, true);

      const quill = new Quill(quillRef.current, {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            ['link', 'image'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ 'size': ['small', false, 'large', 'huge'] }], // フォントサイズオプションを追加
            // [{ 'size': [false, 'large'] }],
            ['clean']
          ],
          imageResize: {
            // オプションを設定（必要に応じて追加可能）
          }
        },
        placeholder: 'Please input the content here...',
      });
      
      quill.on('text-change', () => {
        const content = quill.root.innerHTML;
        setContent(content);
      });
  
      quillRef.current.quill = quill; // 複数回初期化されないようにQuillのインスタンスを保存
    }
  
    return () => {
      // クリーンアップが必要な場合、ここに追加
    };
  }, []);

  useEffect(() => {
    if (selectedFileId) {
      fetchFileContent(selectedFileId);
    }
  }, [selectedFileId]);

  useEffect(() => {
    if (fileIdFromUrl) {
      setSelectedFileId(fileIdFromUrl);
      fetchFileContent(fileIdFromUrl);
    }
  }, [fileIdFromUrl]);

  const handleCopy = (index) => {
    const textarea = document.getElementById(`thought-textarea-${index}`);
    const textareaValue = textarea ? textarea.value : '';
  
    if (textareaValue) {
      textarea.select();
      document.execCommand('copy');
      alert(`Thought ${index + 1} is copied.`);
    } else {
      alert(`Thought ${index + 1} is empyt.`);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleThoughtQuestion = async (index) => {
    const thoughtContent = thoughts[index];
  
    if (!thoughtContent) {
      alert('Thought is empty.');
      return;
    }
  
    try {
      const response = await fetch(`/api/ideaChat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          context: "This is the context of the question",
          question: thoughtContent,
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log(`Thought ${index + 1} の応答:`, data.answer);
        
        setResponseTexts((prevTexts) => [...prevTexts, data.answer]);
        setThoughts((prevThoughts) => [...prevThoughts, '']);
      } else {
        console.error('API呼び出しに失敗しました:', response.statusText);
        alert('API呼び出しに失敗しました');
      }
    } catch (error) {
      console.error('API呼び出し中にエラーが発生しました:', error);
      alert('API呼び出しに失敗しました');
    }
  };
  
  const handleBrainstorming = async () => {
    const question = content.trim();
    const hardCodedContext = `質問内のコンテキストに基づいて質問に答えてください。極力詳細に回答ください。各文は左寄せにしてください。プログラムのソースコードが与えられている場合は差分のみのコードと差分の説明を4096トークン以内で要約して応答してください。必ず4096トークン以内に内容をまとめて文章が途中で途切れないようにしてください。ですます調にならないようにしてください。`;

    if (!question) {
      alert('質問が必要です');
      return;
    }
    try {
      const response = await fetch(`/api/ideaChat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          context: hardCodedContext,
          question: question,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setResponseTexts((prevTexts) => [...prevTexts, data.answer]);
        setThoughts((prevThoughts) => [...prevThoughts, data.answer]);
      } else {
        console.error('API呼び出しに失敗しました:', response.statusText);
        setResponseTexts((prevTexts) => [`Error: API呼び出しに失敗しました`, ...prevTexts]);
      }
    } catch (error) {
      console.error('API呼び出し中にエラーが発生しました:', error);
      setResponseTexts((prevTexts) => [`Error: ${error.message}`, ...prevTexts]);
    }
  };

  const fetchFileContent = async (fileId) => {
    try {
      const response = await fetch(`/api/files/${fileId}`);
      const data = await response.json();
      setSelectedFile(data);
      setFileName(data.fileName);
      const contentEditable = contentEditableRef.current;
      if (contentEditable) {
        contentEditable.innerHTML = data.content;
      }
      // if (quillRef.current && quillRef.current.quill) {
      //   quillRef.current.quill.root.innerHTML = data.content; // Quillエディタにコンテンツを設定
      // }
      if (quillRef.current && quillRef.current.quill) {
        quillRef.current.quill.clipboard.dangerouslyPasteHTML(data.content); // QuillにHTMLとして設定
      }
    } catch (error) {
      console.error('Error fetching file content:', error);
      setErrorMessage('Error fetching file content');
    }
  };

  const fetchSidebarData = async () => {
    try {
      const response = await fetch(`/api/files`);
      const data = await response.json();
      setSidebarData(data); 
    } catch (error) {
      console.error('サイドバーのデータフェッチエラー:', error);
    }
  };

  useEffect(() => {
    fetchSidebarData();
  }, []);

  const toggleRightSidebar = () => {
    setIsRightSidebarOpen(!isRightSidebarOpen);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
  };

  useEffect(() => {
    const fetchFileData = async (fileId) => {
      try {
        const response = await fetch(`/api/files/${fileId}`);
        if (response.ok) {
          const data = await response.json();
          setTitle(data.title);
          if (!contentSet.current) {
            setContent(data.content);
            if (contentEditableRef.current) {
              contentEditableRef.current.innerHTML = data.content;
            }
            contentSet.current = true;
          }
          setFileName(data.file);
        } else {
          const errorText = await response.text();
          console.error('Failed to fetch file data:', errorText);
        }
      } catch (error) {
        console.error('Error fetching file data:', error);
      }
    };

    const params = new URLSearchParams(location.search);
    const fileId = params.get('file-id');
    if (fileId) {
      fetchFileData(fileId);
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const currentFileName = document.querySelector('.file-name').value;

    // const data = {
    //   title,
    //   content: contentEditableRef.current.innerHTML,
    //   file: currentFileName
    // };
    const data = {
      title,
      // content: quillRef.current.root.innerHTML, // Quillから内容を取得
      content,
      file: currentFileName,
    };
    console.log('Sending data:', data);
  
    try {
      const url = fileIdFromUrl 
        ? `/api/save?fileId=${fileIdFromUrl}` 
        : `/api/insert`;

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      console.log('Response status:', response.status);
  
      if (response.ok) {
        const responseData = await response.json();
        console.log('Received response:', responseData);

          // トーストメッセージを表示
  showToastMessage('Data saved successfully!');

        setTitle(responseData.title);
        setContent(responseData.content);
        setFileName(responseData.file);
        if (contentEditableRef.current) {
          contentEditableRef.current.innerHTML = responseData.content;
        }
        console.log('Data saved successfully');
        setErrorMessage('');
        await fetchSidebarData(); 
      } else {
        const errorText = await response.text();
        console.error('Failed to save data:', errorText);
        setErrorMessage(`Failed to save data: ${errorText}`);
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage(`Error: ${error.message}`);
    }
  };

  const handleNewSlide = () => {
    const host = process.env.REACT_APP_API_HOST;
    const url = `https://takashiyamamoto.4pn.takashiyamamoto.com/`;
    //const url = `https://localhost/`;

    window.location.href = url;
  };

  const handleSummary = async () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const selectedText = selection.toString();
      console.log('選択されたテキスト:', selectedText);
      try {
        console.log('要約APIを呼び出します');
        const response = await fetch(`/api/summarize`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ text: selectedText }),
        });

        console.log('要約APIのレスポンスステータス:', response.status);
        if (response.ok) {
          const data = await response.json();
          const summary = data.summary;

          console.log('Summary:', summary);

          await fetch('${apiUrl}/api/logSummary', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ summary }),
          });

          setModalSource('summary');
          setModalContent(summary);
          setShowModal(true);
          console.log('モーダルを表示します');
        } else {
          console.error('Failed to fetch summary:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching summary:', error);
      }
    }
  };

  const handleReload = () => {
    window.location.reload();
  };
  
  return (
    <div className="App">

      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`} ref={sidebarRef}>
        <ListView 
          onSelectFile={(fileId) => {
            setSelectedFileId(fileId);
            localStorage.setItem('selectedFileId', fileId);
            setIsSidebarOpen(false);
            navigate(`/?file-id=${fileId}`);
          }} 
          fetchSidebarData={fetchSidebarData} // fetchSidebarData関数を渡す
          sidebarData={sidebarData} // サイドバーのデータを渡す
        />
      </div>
      
      <div className={`right-sidebar ${isRightSidebarOpen ? 'open' : ''}`}>
  <div style={{ textAlign: 'left', padding: '20px'}}>
    {/* Left column: Thought Section */}
    <div style={{ marginRight: '10px'}}>
      <div className="thought-section" style={{ marginTop: '50px'}}>
        <label>Thought</label>
        <button style={{ marginLeft: '5px' }} onClick={handleBrainstorming}>Think</button>
        
        {/* Staple symbols */}
        {/* <div className="staple-symbol staple-top">]</div>  
        <div className="staple-symbol staple-bottom">]</div>  */}
        
        <textarea
          ref={contentEditableRef}
          onChange={(e) => setContent(e.target.value)}
          className="notebook-textarea"  // Apply CSS class
          style={{ width: '100%', height: '800px' }}
        />
      </div>
    </div>
    
    {/* Right column: Thought {index + 1} Section */}
    <div style={{ flex: 1 }}>
      {responseTexts.map((text, index) => (
        <div key={index} style={{ marginBottom: '0px' }}>
          <div className="thought-section">
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px',marginTop: '50px'}}>
              <label>Thought {index + 1}</label>
              <button style={{ marginLeft: '5px' }} onClick={() => handleCopy(index)}>Copy</button>  
              <button style={{ marginLeft: '5px' }} onClick={() => handleThoughtQuestion(index)}>Think</button>
            </div>
            
            {/* Staple symbols for each section */}
            <div>
              {/* <div className="staple-symbol staple-top">]</div>  
              <div className="staple-symbol staple-bottom">]</div>  */}
              
              <textarea
                id={`thought-textarea-${index}`} // Unique ID for each textarea
                value={thoughts[index] || text}
                onChange={(e) => {
                  const newThoughts = [...thoughts];
                  newThoughts[index] = e.target.value;
                  setThoughts(newThoughts);
                }}
                style={{ width: '100%', height: '800px', marginBottom: '10px' }}
                className="notebook-textarea"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
</div>


      <div className="header fixed-header">
        <div className="left-section">
          <span className="icon" onClick={toggleSidebar} style={{ marginRight: '10px' }}>📄</span>
          <div className="toolbar" style={{ marginRight: '10px' }}>
          <button
            style={{
              // backgroundColor: '#fff',
              // border: '1px solid #b0c4de',
            }}
            onClick={handleNewSlide}
            onMouseDown={(e) => (e.target.style.backgroundColor = '#e0e0e0')}
            onMouseUp={(e) => (e.target.style.backgroundColor = '#fff')}
          >
            New
          </button>
          <button
            style={{
              // backgroundColor: '#fff',
              // border: '1px solid #b0c4de',
            }}
            onClick={handleSubmit}
            onMouseDown={(e) => (e.target.style.backgroundColor = '#e0e0e0')}
            onMouseUp={(e) => (e.target.style.backgroundColor = '#fff')}
          >
            Save
          </button>
          <button
            style={{
              // backgroundColor: '#fff',
              // border: '1px solid #b0c4de',
            }}
            onClick={toggleRightSidebar}
            onMouseDown={(e) => (e.target.style.backgroundColor = '#e0e0e0')}
            onMouseUp={(e) => (e.target.style.backgroundColor = '#fff')}
          >
            Think
          </button>
          {/* <button
            style={{
              backgroundColor: '#fff',
              border: '1px solid #b0c4de',
            }}
            onClick={handleSummary}
            onMouseDown={(e) => (e.target.style.backgroundColor = '#e0e0e0')}
            onMouseUp={(e) => (e.target.style.backgroundColor = '#fff')}
          >
            Brief
          </button> */}
        </div>
        </div>
      </div>
      <div className="container" id="pdf-content">
        <div className="main-content">
          <div className="file-info" style={{ marginBottom: '10px', textAlign: 'left' }}>
          {/* <div className="file-info" style={{ marginBottom: '10px', textAlign: 'left', position: 'fixed', top: '0', left: '0', width: '100%', backgroundColor: 'white', zIndex: '1000' }}> */}
            <input
              type="text"
              className="file-name"
              style={{
                width: '80%',
                height: '30px',
                borderTop: 'none',
                borderLeft: 'none',
                borderRight: 'none',
                // borderBottom: '1px solid #e0e0e0',
                marginLeft: '10px',
                fontSize: '28px',
                wordWrap: 'break-word', // 折り返しを有効にする
              }}
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
              placeholder="Please input the filename here..."
            />
          </div>
          <div className="slide">
              {/* <div
                className="content notebook-background"
                contentEditable="true"
                data-=""
                ref={contentEditableRef}
                style={{
                  minHeight: 'calc(100vh - 200px)',
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  borderBottom: '1px solid #e0e0e0',
                }}
              ></div> */}
              <div 
                ref={quillRef} 
                style={{ 
                  minHeight: 'calc(100vh - 200px)', 
                  borderTop: 'none', 
                  borderLeft: 'none', 
                  borderRight: 'none', 
                  borderBottom: '1px solid #e0e0e0' }}>
              </div>
          </div>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          </div>
          <button className="scroll-to-top" onClick={scrollToTop}>
            <FaArrowUp />
          </button>
          <button className="scroll-to-bottom" onClick={scrollToBottom}>
            <FaArrowDown />
          </button>
          <button className="reload-button" onClick={handleReload}>
            <FaSync />
          </button>
          {showModal && (
            <Modal show={showModal} onClose={() => setShowModal(false)} source={modalSource}>
              <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: modalContent }} />
            </Modal>
          )}
          {showToast && (
  <div className="toast">
    {toastMessage}
  </div>
)}
      </div>
    </div>
  );
}

export default MainApp;
